import { requestCertificateList } from '../Api.js';

const questionsAndAnswers = {
  "does-have-digital-certificate": "",
  "is-juridical-person": "",
  "which-certifate": "",
};
const backButtons = Array.from(document.querySelectorAll('.btn-go-back'));
const quisQuestions = Array.from(document.querySelectorAll('.input-quiz'));

const toggleQuestionClassList = ({ current, next }) => {
  current.classList.add('d-none');
  next.classList.remove('d-none');
}

const handleQuestionOptionClick = ({ target }) => {
  const inputHidden = document.getElementById(target.name);
  const { next, current, questionName } = inputHidden.dataset;
  const currentElement = document.getElementById(current);
  const nextElement = document.getElementById(next);
  const [digitalCertificateQuestion] = Array
    .from(document.querySelectorAll("[name=digital_certificate]"))
    .filter((question) => question.checked);
  const isJuridicalQuestion = document.getElementById('has_cnpj');

  questionsAndAnswers[questionName] = target.value;

  if (digitalCertificateQuestion.value == 'no') {
    isJuridicalQuestion.dataset.next = 'certificate-list';
    questionsAndAnswers['which-certifate'] = false;

    toggleQuestionClassList({
      current: currentElement,
      next: nextElement,
    })
    return;
  } else {
    isJuridicalQuestion.dataset.next = 'which-certifate';
  }

  console.log(questionsAndAnswers);

  if (next == 'certificate-list') {
    console.log('last-question');
    console.log('nextElement', nextElement)
    // faz a request aqui pra escolher o produto
  }

  toggleQuestionClassList({
    current: currentElement,
    next: nextElement
  });
};

const handleGoToPreviousQuestion = ({ target }) => {
  toggleQuestionClassList({
    current: document.getElementById(target.dataset.current),
    next: document.getElementById(target.dataset.next)
  })
}

backButtons.forEach((backButton) => {
  backButton.addEventListener('click', handleGoToPreviousQuestion);
});

quisQuestions.forEach((quizQuestion) => {
  quizQuestion.addEventListener('click', handleQuestionOptionClick)
})
